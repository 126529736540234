<template>
  <v-layout class="column">
    <v-card
      class="elevation-0"
      :outlined="outlined"
    >
      <HeaderCard>
        <b>{{ 'title.auth'.translate() }}</b>
      </HeaderCard>

      <v-card-text style="padding-top: 0; padding-bottom: 0">
        <v-form
          ref="form"
          v-model="valid"
          autocomplete="off"
        >
          <IgnoreAutoFill />

          <v-layout
            wrap
            style="padding: 12px 0"
          >
            <v-flex xs12>
              <FieldEmail
                v-model="username"
                icon="mdi-shield-account"
                autofocus
                @on-enter="loginUser"
              />
            </v-flex>

            <v-flex xs12>
              <FieldPassword
                v-model="password"
                @on-enter="loginUser"
              />
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>

      <Message
        type="error"
        icon="mdi-cloud-alert"
        :show="showError"
        :message="auth.error"
      />

      <v-divider />

      <v-card-actions style="padding-top: 0.7em; padding-bottom: 0.7em;">
        <v-layout
          wrap
          justify-space-around
        >
          <v-btn
            v-if="showLogout"
            text
            small
            color="primary"
            @click="userLogout()"
          >
            {{ 'btn.logout'.translate() }}
          </v-btn>
          <v-btn
            v-if="showRenew"
            depressed
            small
            color="primary"
            :loading="loading"
            :disabled="!valid"
            @click="renewUser()"
          >
            {{ 'btn.renew'.translate() }}
          </v-btn>

          <v-btn
            v-if="showRecovery"
            text
            small
            color="primary"
            :to="linkRecoveryPass"
          >
            {{ 'btn.recovery'.translate() }}
          </v-btn>

          <!-- <v-btn
          v-if="showRegister"
          text
          small
          color="primary"
          :to="linkCreateUser"
        >
          {{ 'btn.register'.translate() }}
        </v-btn> -->

          <v-btn
            v-if="showLogin"
            depressed
            small
            color="primary"
            :loading="loading"
            :disabled="!valid"
            @click="loginUser()"
          >
            {{ 'btn.access'.translate() }}
          </v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>

    <!-- <v-btn
      class="mt-2"
      text
      x-small
      color="blue-grey darken-1"
      :to="linkRemoveAccount"
    >
      {{ 'btn.unregister'.translate() }}
    </v-btn> -->
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import Message from '@/components/Message'
import HeaderCard from '@/components/HeaderCard'
import FieldEmail from '@/components/fields/FieldEmail'
import FieldPassword from '@/components/fields/FieldPassword'
import IgnoreAutoFill from './IgnoreAutoFill'

import {
  ACTION_USER_LOGIN,
  ACTION_USER_LOGOUT,
  ROUTE_AUTH_CREATE,
  ROUTE_AUTH_RECOVERY,
  ROUTE_REMOVE_ACCOUNT
} from '@/constants'

export default {
  components: {
    Message,
    HeaderCard,

    FieldEmail,
    FieldPassword,
    IgnoreAutoFill
  },

  props: {
    outlined: { default: true },
    showRenew: { default: false },
    showLogout: { default: false },

    showLogin: { default: true },
    showRecovery: { default: true },
    showRegister: { default: true }
  },

  data () {
    return {
      valid: true,
      loading: false,
      showError: false,

      username: '',
      password: null
    }
  },

  methods: {
    loginUser () {
      if (!this.valid) return
      if (this.loading) return
      this.loading = true

      this.$nextTick(() => this.$store.dispatch(ACTION_USER_LOGIN, {
        username: this.username,
        password: this.password
      }))
    },

    userLogout () {
      this.$store.dispatch(ACTION_USER_LOGOUT)
    }
  },

  computed: {
    linkCreateUser: () => ({ name: ROUTE_AUTH_CREATE }),
    linkRecoveryPass: () => ({ name: ROUTE_AUTH_RECOVERY }),
    linkRemoveAccount: () => ({ name: ROUTE_REMOVE_ACCOUNT }),

    ...mapState({
      auth ({ auth }) {
        this.showError = Boolean(auth.error)
        return { error: auth.error, loading: auth.loading }
      }
    })
  },

  watch: {
    username () {
      this.showError = false
    },

    password () {
      this.showError = false
    },

    'auth.loading' (loading) {
      if (this.loading !== loading) this.loading = loading
    }
  }
}
</script>
